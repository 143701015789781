<template>
  <Header />
  <router-view />
</template>


<script>
import Header from './components/Header.vue';
export default {
  name: "App",
  components: {
    Header
  }
}
</script>

<style>
#app {
  font-family: BeVietnamPro, Helvetica, Arial, sans-serif;
  margin: 0;
  background: var(--dark-bg);
  min-height: 100vh;
}


:root {
  --dark-bg: #05092B;
  --white:#fff;
  --blue-bg:#3771FE;
}

@font-face {
  font-family: BeVietnamPro;
  src: url(./assets/fonts/BeVietnamPro-Regular.ttf);
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: BeVietnamPro;
  src: url(./assets/fonts/BeVietnamPro-Medium.ttf);
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: BeVietnamPro;
  src: url(./assets/fonts/BeVietnamPro-SemiBold.ttf);
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: BeVietnamPro;
  src: url(./assets/fonts/BeVietnamPro-Bold.ttf);
  font-weight: 700;
  font-display: swap;
}
</style>
