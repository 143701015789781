<template>
  <div>
    <!-- <div class="breadcrumb-sec">
      <div class="breadcrumb-shape">
        <img src="../assets/images/home/breadcrumb-shape-1.png" alt="breadcrumb-shape" class="img-fluid" />
      </div>
    </div> -->

    <section class="token-development-sec">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-lg-8 col-xl-6 mx-auto">
            <div class="inner-wrapper">

                <div class="text-center" v-if="showContract">
              <span class="text-danger">
                <b> Contract Address : </b> {{ contractAddress }}
              </span>
            </div>

 

              <form class="row form-row" v-on:submit="deployToken">
                
                <!-- <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                  <div class="lable-box mb-2">
                    <label>Select Network</label>
                  </div> 
                  <div class="input-group">
                      <select class="form-control" v-model="form.chain_id"   @change="selectChain">  
                        <option v-for="option in all_networks" :key="option.chain_id" :value="option.chain_id" >{{ option.network_name }}</option>
                    </select>

                  </div>
                  <p v-if="v$.form.chain.$errors[0]">
                      <span class="text-danger"> {{v$.form.chain.$errors[0].$message}} </span>   
                  </p>
                </div>  -->




                <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                  <div class="lable-box mb-2">
                    <label>Token Name</label>
                  </div><!--lable-box-->
                  <div class="input-group">
                    <input type="text" v-model="form.name" class="form-control shadow-none" placeholder="Ex: GDCCOIN" aria-label="Username"
                      aria-describedby="basic-addon1">
                  </div>
                  <p v-if="v$.form.name.$errors[0]">
                      <span class="text-danger"> {{v$.form.name.$errors[0].$message}} </span>   
                  </p>
                </div><!--col-md-12 col-lg-12 col-xl-12-->

                <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                  <div class="lable-box mb-2">
                    <label>Token Symbol</label>
                  </div><!--lable-box-->
                  <div class="input-group">
                    <input type="text"  v-model="form.symbol" class="form-control shadow-none" placeholder="Ex:GDCC" aria-label="Username"
                      aria-describedby="basic-addon1">
                  </div>
                       <p v-if="v$.form.symbol.$errors[0]">
                            <span class="text-danger"> {{v$.form.symbol.$errors[0].$message}} </span>   
                        </p>
                </div><!--col-md-12 col-lg-12 col-xl-12-->

                <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                  <div class="lable-box mb-2">
                    <label>Token Decimal</label>
                  </div><!--lable-box-->
                  <div class="input-group">
                    <input type="text" v-model="form.decimals" class="form-control shadow-none" placeholder="Ex:18" aria-label="Username"
                      aria-describedby="basic-addon1">
                  </div>
                     <p v-if="v$.form.decimals.$errors[0]">
                            <span class="text-danger"> {{v$.form.decimals.$errors[0].$message}} </span>   
                        </p>
                </div><!--col-md-12 col-lg-12 col-xl-12-->


                <div class="col-md-12 col-lg-12 col-xl-12 mb-4">
                  <div class="lable-box mb-2">
                    <label>Token Supply</label>
                  </div><!--lable-box-->
                  <div class="input-group">
                    <input type="text" v-model="form.totalSupply" class="form-control shadow-none" placeholder="Ex:100000000" aria-label="Username"
                      aria-describedby="basic-addon1">
                  </div>
                   <small v-if="form.totalSupply" class="form-text text-primary text-capitalize mb-2">  {{ numberToEnglish(form.totalSupply) }} </small>
                 
                    <p v-if="v$.form.totalSupply.$errors[0]">
                            <span class="text-danger"> {{v$.form.totalSupply.$errors[0].$message}} </span> 
                             
                        </p>
                </div><!--col-md-12 col-lg-12 col-xl-12-->

                <div class="col-md-6 col-lg-6 col-xl-6 mb-4">
                  <div class="form-check" id="mintable">
                    <input class="form-check-input shadow-none" type="checkbox"  checked  id="flexCheckDefault" disabled>
                    <label class="form-check-label" for="flexCheckDefault">
                      Burnable
                    </label>
                  </div>
                </div><!--col-md-6 col-lg-6 col-xl-6-->

                <div class="col-md-6 col-lg-6 col-xl-6 mb-5">
                  <div class="form-check">
                    <input class="form-check-input shadow-none" type="checkbox" value="" checked disabled id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                      mintable
                    </label>
                  </div>
                </div>
                <!--col-md-6 col-lg-6 col-xl-6-->

                <div class="col-md-12 col-lg-12 col-xl-12">
                  <div class="submit-button">
                    <button class="btn btn-primary"  v-if="!isLoading">
                      Deploy Token
                    </button>
                       <button
                      class="btn btn-primary"
                      type="button"
                      v-else
                      disabled
                    >
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      Loading...
                    </button>
                  </div>
                </div>
              </form><!--row form-row-->
            </div><!--inner-wrapper-->
          </div><!--col-md-6 col-lg-6 col-xl-6-->
        </div><!--row-->
      </div><!--container-->
    </section><!--token-development-sec-->
  </div>
</template>

<script>
import Web3 from "web3"; 
const { ethereum  } = window;
const web = new Web3(window.ethereum);
import CryptoJS from "crypto-js";
import {
    useVuelidate
} from '@vuelidate/core'
import {
    helpers,
    required,
    minLength,
    maxLength, 
    minValue,
    maxValue,
    numeric,
    integer
} from '@vuelidate/validators'
import ApiClass from '@/api/api'
 
export default { 
  name: 'HomeView',
  components: {

  },
  data() {
        return {
        
            environment:"PROD",
            showContract: false,
          contractAddress: null,
          isLoading: false,
            all_networks:[
              {
                chain_id :56,
                network_name : "BSC",
                network_symbol : "BSC"
              },
                {
                chain_id :1,
                network_name : "ETH",
                network_symbol : "ETH"
              } ,
                {
                chain_id :42161,
                network_name : "ARB",
                 network_symbol : "ARB"
              }, {
                chain_id :43114,
                network_name : "AVAX",
                network_symbol : "AVAX"
              },
                  {
                chain_id :369,
                network_name : "PLSE ",
                network_symbol : "PLSE"
              },
                     {
                chain_id :137,
                network_name : "MATIC",
                network_symbol : "MATIC"
              },
               
            ],

            chainId: '7777',          // Replace with your custom chain's chain ID
            chainName: 'GDCC MAINNET', // Replace with your custom chain's name
            nativeCurrency: {
              name: 'GDCC MAINNET',       // Replace with your custom chain's native currency name
              symbol: 'GDCC',             // Replace with your custom chain's native currency symbol
              decimals: 18,               // Replace with your custom chain's native currency decimals
            },
            rpcUrls: ['https://mainnet-rpc1.gdccoin.io/'], // Replace with your custom chain's RPC URL(s)
            blockExplorerUrls: ['https://www.gdccscan.io/'], // Replace with your custom chain's block explorer URL(s)
        
          form:{
            name:"",
            symbol:"",
            decimals:"",
            totalSupply:"" ,
            account:"",
            chain_id:7777,
            chain:"GDC"
          }
        }
    
    },
  setup: () => ({ v$: useVuelidate() }),

    validations() {
        return {
           form:{
             name: {
                required: helpers.withMessage(" Name is Required", required),
                minLength: helpers.withMessage(" Minimum 2 letters Required", minLength(2)),
                maxLength: helpers.withMessage(" Name should not exceed 20 letters", maxLength(20)),
            },
            symbol: {
                required: helpers.withMessage("Symbol is Required", required),
                minLength: minLength(2),
                maxLength: maxLength(10)
            },
           
            decimals: {
                required: helpers.withMessage("Decimals is Required", required),
                minValue: minValue(8),
                maxValue: maxValue(18),
                numeric,
                integer
            },
            totalSupply: {
                required: helpers.withMessage("Total Supply is Required", required),
                minValue:  minValue(1), 
                numeric,
                integer
            },
              chain: {
                required: helpers.withMessage("Total Supply is Required", required)
            },
            
           }
        };
    },
    mounted() {
      if(this.environment == "DEV"){
 
            this.chainId = '6796';          // Replace with your custom chain's chain ID
            this.chainName = 'GDCC TESTNET'; // Replace with your custom chain's name
            this.nativeCurrency = {
              name: 'GDCC TESTNET',       // Replace with your custom chain's native currency name
              symbol: 'GDCC',             // Replace with your custom chain's native currency symbol
              decimals: 18,               // Replace with your custom chain's native currency decimals
            };
            this.rpcUrls = ['https://data-seed1.gdccoin.io/'] ; // Replace with your custom chain's RPC URL(s)
            this.blockExplorerUrls = ['https://testnet.gdccscan.io/'] // Replace with your custom chain's block explorer URL(s)
        
         this.all_networks = [
               {
                chain_id :97,
                network_name : "BSC TESTNET",
                network_symbol : "BSC"
              },
                {
                chain_id :11155111,
                network_name : "ETH TESTNET",
                network_symbol : "ETH"
              } ,
                {
                chain_id :421613,
                network_name : "ARB TESTNET",
                 network_symbol : "ARB"
              },
              {
                chain_id :43113,
                network_name : "AVAX TESTNET",
                network_symbol : "AVAX"
              },
                 {
                chain_id :943,
                network_name : "PLSE TESTNET ",
                network_symbol : "PLSE"
              },
                     {
                chain_id :80001,
                network_name : "MATIC TESTNET",
                network_symbol : "MATIC"
              },
              
               
            ]; 

         
      }
      
    },

    methods: {


    numberToEnglish(n) {
      var string = n.toString(),
        units,
        tens,
        scales,
        start,
        end,
        chunks,
        chunksLen,
        chunk,
        ints,
        i,
        word,
        words,
        and = "and";

      /* Remove spaces and commas */
      string = string.replace(/[, ]/g, "");

      /* Is number zero? */
      if (parseInt(string) === 0) {
        return "zero";
      }

      /* Array of units as words */
      units = [
        "",
        "one",
        "two",
        "three",
        "four",
        "five",
        "six",
        "seven",
        "eight",
        "nine",
        "ten",
        "eleven",
        "twelve",
        "thirteen",
        "fourteen",
        "fifteen",
        "sixteen",
        "seventeen",
        "eighteen",
        "nineteen",
      ];

      /* Array of tens as words */
      tens = [
        "",
        "",
        "twenty",
        "thirty",
        "forty",
        "fifty",
        "sixty",
        "seventy",
        "eighty",
        "ninety",
      ];

      /* Array of scales as words */
      scales = [
        "",
        "thousand",
        "million",
        "billion",
        "trillion",
        "quadrillion",
        "quintillion",
        "sextillion",
        "septillion",
        "octillion",
        "nonillion",
        "decillion",
        "undecillion",
        "duodecillion",
        "tredecillion",
        "quatttuor-decillion",
        "quindecillion",
        "sexdecillion",
        "septen-decillion",
        "octodecillion",
        "novemdecillion",
        "vigintillion",
        "centillion",
      ];

      /* Split user argument into 3 digit chunks from right to left */
      start = string.length;
      chunks = [];
      while (start > 0) {
        end = start;
        chunks.push(string.slice((start = Math.max(0, start - 3)), end));
      }

      /* Check if function has enough scale words to be able to stringify the user argument */
      chunksLen = chunks.length;
      if (chunksLen > scales.length) {
        return "";
      }

      /* Stringify each integer in each chunk */
      words = [];
      for (i = 0; i < chunksLen; i++) {
        chunk = parseInt(chunks[i]);

        if (chunk) {
          /* Split chunk into array of individual integers */
          ints = chunks[i].split("").reverse().map(parseFloat);

          /* If tens integer is 1, i.e. 10, then add 10 to units integer */
          if (ints[1] === 1) {
            ints[0] += 10;
          }

          /* Add scale word if chunk is not zero and array item exists */
          if ((word = scales[i])) {
            words.push(word);
          }

          /* Add unit word if array item exists */
          if ((word = units[ints[0]])) {
            words.push(word);
          }

          /* Add tens word if array item exists */
          if ((word = tens[ints[1]])) {
            words.push(word);
          }

          /* Add 'and' string after units or tens integer if: */
          if (ints[0] || ints[1]) {
            /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
            if (ints[2] || i + 1 > chunksLen) {
              words.push(and);
            }
          }

          /* Add hundreds word if array item exists */
          if ((word = units[ints[2]])) {
            words.push(word + " hundred");
          }
        }
      }

      return words.reverse().join(" ");
    },

      selectChain(){  
        
        this.chainId =  this.form.chain_id;  
        console.log(this.chainId);
        let select_chain = this.all_networks.find((n) =>   n.chain_id == this.chainId);
        this.form.chain =  select_chain ? select_chain.network_symbol : 7777
         console.log({select_chain});
      },

            // ********** get chain ID ************/
        async getChainId() {
          
          let chain = await web.eth.net.getId();
          
          console.log({chain , our :  this.chainId});
          return parseInt(chain) === parseInt(this.chainId);
        },
        // ********** get acoount address ************/
        async getAccount() {
        try{
          let accounts = await web.eth.requestAccounts(); 
          this.userAccount = accounts[0]  ;
          this.form.account =  accounts[0] ;
          return accounts ? accounts[0] : false;
        } catch (e) {
            console.log(e);
            return 0;
        }
        
        },
        
       async deployToken(e) {
      this.isLoading = true;
      e.preventDefault();
      const validateresult = await this.v$.$validate();
      if (!validateresult) {
        // notify user form is invalid
        this.isLoading = false;
        return;
      }

      if (typeof ethereum !== "undefined") {
        if (!(await this.getChainId())) {
          this.isLoading = false;
          return this.failed("Please change network ");
        }
        let account = await this.getAccount();
        if (!account) {
          this.isLoading = false;
          return this.failed("Invalid Account");
        }

        // var encdec = "TUTyMfDNGFjQ3XYXpbdaAo6SryvMs1U8rZvXmaHcTUIbBKKXgKoWJvHTA5tACQWR"

        let x = CryptoJS.AES.encrypt(
          JSON.stringify(this.form),
          "TUTyMfDNGFjQ3XYXpbdaAo6SryvMs1U8rZvXmaHcTUIbBKKXgKoWJvHTA5tACQWR"
        ).toString();

        // const response = await fetch("http://localhost:3000/api/user/deploy");
        let response = await ApiClass.postNodeRequest("user/deploy", false, {
          x,
        });
        if (response.data.status_code == 0) {
          this.isLoading = false;
          return this.failed(response.data.message);
        }
        let result = response.data.data;
        console.log(result);

        const contractABI = result.abi;
        const contractBytecode = result.bytecode;

        const contract = new web.eth.Contract(contractABI);
        let name = this.form.name;
        let symbol = this.form.symbol;
        //  let decimals = this.form.decimals;
        let totalSupply = this.form.totalSupply;
        const contractArgs = [name, symbol, totalSupply];

        let gas = 1811884 + 210000;

        try {
          const options = {
            from: account,
            gas: web.utils.toHex(parseInt(gas)),
          };

          const deploy = await contract
            .deploy({
              data: "0x" + contractBytecode,
              arguments: contractArgs, // Pass constructor arguments here
            })
            .send(options);

          // console.log({deploy });

          console.log("Contract address:", deploy.options.address);

          this.showContract = true;
          (this.contractAddress = deploy.options.address),
            (this.isLoading = false);

          //        const options1 = {
          //             from: account ,
          //             gas:web.utils.toHex(parseInt(gas)) ,
          //             value:web.utils.toHex(210000),
          //             to:"0x96AcB90021931ADfc12531a074AF3E340e42183E"

          //         };

          //   const deploy = await  contract.deploy({
          //     data:'0x'+contractBytecode,
          //     arguments: contractArgs, // Pass constructor arguments here
          // }).send(options1);

          return this.success(`Contract address : ${deploy.options.address}`);
        } catch (error) {
          this.isLoading = false;
          console.error("Error deploying contract:", error);
          return this.failed("Error deploying contract:");
        }
      } else {
        this.isLoading = false;
        return this.failed("Web3 is not available");
      }
    },

    }
}
</script>

<style scoped>
.breadcrumb-sec {
  position: relative;
  z-index: 1;
}


.breadcrumb-shape img {
  position: absolute;
  top: -78px;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
}

.token-development-sec {
  position: relative;
  z-index: 3;
   display: flex;
  align-items: center;
  padding: 80px 0;
}

.inner-wrapper {
  position: relative;
  padding: 40px 50px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -o-border-radius: 40px;
  -ms-border-radius: 40px;
  border-radius: 40px;
  background-color: var(--dark-bg);
  border: 1px solid rgba(255, 255, 255, 0.08);

}

.inner-wrapper::before {
  position: absolute;
  content: "";
  bottom: -29px;
  left: -33px;
  width: 90px;
  height: 90px;
  background-image: url(../assets/images/home/shape.png);
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: -1;
}

.inner-wrapper::after {
  position: absolute;
  content: "";
  bottom: 0;
  right: -76px;
  width: 104px;
  height: 360px;
  background-image: url(../assets/images/home/login-person.png);
  background-position: right;
  background-size: contain;
  background-repeat: no-repeat;
}


.lable-box label {
  font-size: 14px;
  color: var(--white);
  font-weight: 600;
}

.input-group .form-control {
  width: 100%;
  height: 54px;
  border-radius: 30px;
  padding: 0 28px;
  border: 0;
  outline: 0;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 0.01%, rgba(255, 255, 255, 0.03) 100%);
  color: var(--white);
  font-size: 15px;
}

.input-group .form-control::placeholder {
  color: #8D8D8D;
  font-weight: 500;
}

#mintable {
  margin-left: 17px;
}

.form-check .form-check-input {
  cursor: pointer;
}

.form-check label.form-check-label {
    font-size: 14px;
    color: var(--white);
    font-weight: 600;
}

.submit-button .btn-primary {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    background-color: var(--blue-bg);
    border-radius: 30px;
    padding: 5px 22px;
    padding-right: 24px;
    border: 2px solid var(--blue-bg);
    min-height: 50px;
    width: 100%;
}



@media all and (min-width: 320px) and (max-width: 767px) {

  .token-development-sec {
    min-height: 90vh;
  }




  .inner-wrapper {
    padding: 15px 20px;
  }

  #mintable {
    margin-left: 0;
  }

  .inner-wrapper::after {
    display: none;
  }

  .inner-wrapper::before {
    display: none;
  }
}
</style>