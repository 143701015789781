import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/LoginView.vue')
  },

  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/auth/RegisterView.vue')
  },

  {
    path: '/forgetpassword',
    name: 'forgetpassword',
    component: () => import(/* webpackChunkName: "forgetpassword" */ '../views/auth/ForgetPassword.vue')
  },
 /**=============otp================= */
{
  path: '/otp',
  name: 'otp',
  component: () => import(/* webpackChunkName: "Otp" */ '../views/auth/OtpView.vue')
},
 

  {
    path: '/userpannel',
    name: 'userpannel',
    component: () => import(/* webpackChunkName: "userpannel" */ '../views/auth/UserPannel.vue')
  },

  
  {
    path: '/deployed',
    name: 'deployed',
    component: () => import(/* webpackChunkName: "deployed" */ '../views/auth/DeployedForm.vue')
  },
 


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
