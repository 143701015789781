<template>
    <div>
        <header class="head">
            <nav class="navbar navbar-expand-lg p-0">
                <div class="container">
                    <a class="navbar-brand" href="/"><img src="../assets/logo.png" class="img-fluid" alt="logo" /></a>
                    <button class="navbar-toggler shadow-none" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul class="navbar-nav ms-auto mb-2 mb-lg-0 gap-3">

                            <li class="nav-item">
                                <router-link to="" class="nav-link"><span> <img src="../assets/images/icons/metamask.png" alt="" class="img-fluid" />
                                    </span>Add GDCC Mainnet</router-link>
                            </li>

                            <li class="nav-item">
                                <button class="btn btn-primary" @click="addChain">GDCC Chain</button>
                            </li>

                            <li class="nav-item" v-if="!userAccount">
                                <button class="btn btn-primary" @click="getAccount">Connect Wallet</button>
                            </li>
                              <li class="nav-item" v-else>
                                <button class="btn btn-primary"  >{{ acc}}</button>
                            </li>

                        </ul>

                    </div>
                </div><!--container-->
            </nav><!--navbar-->
        </header><!--head-->
    </div>
</template>

<script>
import Web3 from "web3"; 
const { ethereum  } = window;
const web = new Web3(window.ethereum);
export default {
    name: "HeaderComponent",

    data() {
        return {
            environment:"PROD",
            acc:"",
            userAccount:null,
            customChain:{
            chainId: '0x1E61',      // 7777    // Replace with your custom chain's chain ID
            chainName: 'GDCC MAINNET', // Replace with your custom chain's name
            nativeCurrency: {
              name: 'GDCC MAINNET',       // Replace with your custom chain's native currency name
              symbol: 'GDCC',             // Replace with your custom chain's native currency symbol
              decimals: 18,               // Replace with your custom chain's native currency decimals
            },
            rpcUrls: ['https://mainnet-rpc1.gdccoin.io/'], // Replace with your custom chain's RPC URL(s)
            blockExplorerUrls: ['https://www.gdccscan.io/'], // Replace with your custom chain's block explorer URL(s)
        
            }
          
        }
    },
    mounted() {
         if(this.environment == "DEV"){
  
            this.customChain.chainId = '0x1A7C';     // 6796    // Replace with your custom chain's chain ID
            this.customChain.chainName = 'GDCC TESTNET'; // Replace with your custom chain's name
            this.customChain.nativeCurrency = {
              name: 'GDCC TESTNET',       // Replace with your custom chain's native currency name
              symbol: 'GDCC',             // Replace with your custom chain's native currency symbol
              decimals: 18,               // Replace with your custom chain's native currency decimals
            };
            this.customChain.rpcUrls = ['https://data-seed1.gdccoin.io/'] ; // Replace with your custom chain's RPC URL(s)
            this.customChain.blockExplorerUrls = ['https://testnet.gdccscan.io/'] // Replace with your custom chain's block explorer URL(s)
         
      }
    },

    methods: {

    // ********** get chain ID ************/
    async getChainId() {
      let chainId = await web.eth.net.getId();
      // return chainId === 56; //56;
      return chainId === this.customChain.chainId;
    },
    // ********** get acoount address ************/
   async getAccount() {
      try {
        let accounts = await web.eth.requestAccounts();
        this.userAccount = accounts[0]; 
        this.acc = `${accounts[0]?.substring(
          0,
          5
        )}....${accounts[0]?.slice(-5)}`;
        return accounts ? accounts[0] : false;
      } catch (e) {
        console.log(e);
        return 0;
      }
    },
     
        async addChain(){
    
            if (!ethereum || ethereum.length <= 0) { 
                return this.failed("Please Install Metamask ");
            }
            let account = await this.getAccount();
            if (!account) { 

                return this.failed("Invalid Account");
            }

        if (ethereum) {
        
        try {
        
        let info_rpc = JSON.parse(JSON.stringify(this.customChain));
        console.log({info_rpc});
        // Add the custom chain
        await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [ info_rpc],
        });

        return this.success("Custom chain added successfully!"); 
        } catch (error) {
            console.log({error});
            return this.failed("Failed to add Custom chain !");  
        }
    } else {
        return this.failed("Metamask extension not found. Please install Metamask.");  
    }

        }
    }
}
</script>

<style>
header.head {
    background-color: rgba(255, 255, 255, 0.04);
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    padding: 10px 0;
    position: relative;
 
}

.navbar-brand img {
    max-width: 25%;
}

ul.navbar-nav {
    align-items: center;
}

ul.navbar-nav li.nav-item .btn-primary {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    background-color: var(--blue-bg);
    border-radius: 30px;
    padding: 5px 22px;
    padding-right: 24px;
    border: 2px solid var(--blue-bg);
    min-height: 40px;
}

.navbar-toggler-icon {
    filter: invert(1);
}

button#dropdown {
    min-width: 140px;
    display: flex;
    align-items: center;
    gap: 11px;
}

ul.dropdown-menu.show {
    padding: 10px 10px;
    background: #181C3B;
}

ul.dropdown-menu.show li {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--white);
    font-size: 15px;
    font-weight: 600;
    transition: all 0.5s ease;
    cursor: pointer;
    padding: 5px 5px;
    border-radius: 5px;
}

ul.dropdown-menu.show li:hover {
    background-color: #242845;
    transition: all 0.5s ease;
    
}
ul.dropdown-menu.show li:nth-child(2) {
    margin-bottom: 0;
}

ul.navbar-nav li.nav-item a img {
    max-width: 30px;
    margin-right: 9px;
}

ul.navbar-nav li.nav-item a {
    color: var(--white);
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
}

@media all and (min-width: 320px) and (max-width: 767px) {
    .navbar-brand {
        width: 70%;
    }
}
</style>