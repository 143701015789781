import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const mixins = {
    methods: {
      getDate: function (date, time) {
        let d =  new Date(date).toLocaleDateString("en-US")
        if (time) {
        return d +" " +new Date(date).toLocaleTimeString("en-US", {hour12: false});
        }
        return d ;
  
      },
      toastMessage: function (icon, message) {
        this.$swal({
          title: message,
          position: "top-end",
          icon,
          toast: true,
          timer: "3000",
          showConfirmButton: false,
        });
      },
      success: function (message) {
        this.$swal({
          title: message,
          position: "top-end",
          icon: "success",
          toast: true,
          timer: "3000",
          showConfirmButton: false,
        });
      },
      failed: function (message) {
        this.$swal({
          title: message,
          position: "top-end",
          icon: "error",
          toast: true,
          timer: "3000",
          showConfirmButton: false,
        });
      },
    },
  };


createApp(App).use(VueSweetalert2).use(store).use(router).mixin(mixins).mount('#app')
